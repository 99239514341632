<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <tempo-ratio-logo />
        <h2 class="brand-text text-primary ml-1">Tempo<span>Ratio</span></h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Willkommen bei TempoRatio!
          </b-card-title>
          <b-card-text class="mb-2">
            Bitte melden Sie sich mit Ihren Zugangsdaten an.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <!-- TODO: Passwort recovery programmieren
                  <div class="d-flex justify-content-between">
                  <label for="login-password">Passwort</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Passwort vergessen?</small>
                  </b-link>
                </div> 
                -->
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Zugangsdaten merken
                </b-form-checkbox>
              </b-form-group>
              -->
              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="login">
                Anmelden
              </b-button>
            </b-form>
          </validation-observer>

          <!-- TODO: Account erstellung
          <b-card-text class="text-center mt-2">
            <span>Noch keinen Account? </span>
            <b-link :to="{ name: 'auth-account-erstellen' }">
              <span>&nbsp;Account erstellen</span>
            </b-link>
          </b-card-text>
          -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import TempoRatioLogo from '@/layouts/components/TempoRatioLogo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useJwt from '@/auth/jwt/useJwt';
import { isUserLoggedIn, getUserData } from '@/auth/utils';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import userAbilities from './userAbilities';

localize('de', de);

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    TempoRatioLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login/login-image.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/login-image.png');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async setUserData(userID) {
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['username', 'email'],
          populate: {
            role: {
              fields: 'name',
            },
            avatar: {
              fields: 'formats',
            },
            Mitarbeiter: {
              fields: ['id', 'Vorname', 'Nachname'],
            },
            Kunde: {
              fields: ['id', 'Firma'],
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/users/${userID}?${query}`)
        .then(response => {
          const userData = {
            username: response.data.username,
            email: response.data.email,
            mitarbeiterID: response.data.Mitarbeiter
              ? response.data.Mitarbeiter.id
              : null,
            mitarbeiterName: response.data.Mitarbeiter
              ? `${response.data.Mitarbeiter.Vorname} ${response.data.Mitarbeiter.Nachname}`
              : null,
            kundenID: response.data.Kunde ? response.data.Kunde.id : null,
            kundenName: response.data.Kunde
              ? `${response.data.Kunde.Firma}`
              : null,
            role: response.data.role.name,
            avatar: response.data.avatar
              ? `${this.$dbBaseUrl}${response.data.avatar.formats.thumbnail.url}`
              : null,
            ability: userAbilities[response.data.role.name],
          };
          localStorage.setItem('userData', JSON.stringify(userData));

          this.$ability.update(userAbilities[response.data.role.name]);
        })
        .catch(err => console.log(err));
    },
    goToDashboard() {
      this.$router.replace({ name: 'home' }).then(() => {
        const userData = getUserData();
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Willkommen ${
              userData.fullName || userData.mitarbeiterName || userData.username
            }`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `Sie haben sich erfolgreich als ${userData.role} angemeldet.`,
          },
        });
      });
    },
    login() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          if (!isUserLoggedIn()) {
            await useJwt
              .login({
                identifier: this.userEmail,
                password: this.password,
              })
              .then(async response => {
                const userID = response.data.user.id;
                localStorage.setItem('userID', userID);
                useJwt.setToken(response.data.jwt);
                await this.setUserData(userID);
                this.goToDashboard();
              })
              .catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Anmeldung fehlgeschlagen!`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: `Die Email-Adresse oder das Passwort ist nicht gültig. Bitte probieren Sie es nochmal.`,
                  },
                });
                this.password = '';
                this.userEmail = '';
                console.log(err.response.data.error.message);
              });
          } else {
            await this.setUserData(localStorage.getItem('userID'));
            this.goToDashboard();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
h2 span {
  color: #6e6b7b;
}
</style>
