const userAbilities = {
  Administrator: [
    { action: 'read', subject: 'All' },
    { action: 'read', subject: 'Auth' },
    { action: 'read', subject: 'Admin' },
    { action: 'read', subject: 'AdminMitarbeiter' },
    { action: 'read', subject: 'AdminKunde' },
  ],
  Mitarbeiter: [
    { action: 'read', subject: 'All' },
    { action: 'read', subject: 'Auth' },
    { action: 'read', subject: 'Mitarbeiter' },
    { action: 'read', subject: 'AdminMitarbeiter' },
  ],
  Kunde: [
    { action: 'read', subject: 'All' },
    { action: 'read', subject: 'Auth' },
    { action: 'read', subject: 'Kunde' },
    { action: 'read', subject: 'AdminKunde' },
  ],
};

export default userAbilities;
